import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4-4-4-4\\@80% 1RM`}</p>
    <p>{`Band Pull Aparts 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`9-Bar Muscle Ups`}</p>
    <p>{`21-Push Jerks (95/65)`}</p>
    <p>{`7-Bar Muscle Ups`}</p>
    <p>{`15-Push Jerks`}</p>
    <p>{`5-Bar Muscle Ups`}</p>
    <p>{`9-Push Jerks`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed this Saturday because of street closings
from 6am-12pm for the Color Run.  East will be open for normal class
hours at 10:00 & 11:00am and there will be open gym from 12-2pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      